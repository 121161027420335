<template>
  <div class="row row-no-padding row-col-separator-xl">
    <div class="col-md-12">
      <apex-heatmap-chart v-if="chartType == 'heatmap'" :data-url="chartDataUrl"></apex-heatmap-chart>

      <!--<apex-bubble-chart v-else-if="chartType == 'bubble'" :data-url="chartDataUrl"></apex-bubble-chart>

      <apex-line-chart v-else-if="chartType == 'line'" :data-url="chartDataUrl"></apex-line-chart>

      <apex-pie-chart v-else-if="chartType == 'pie'" :data-url="chartDataUrl"></apex-pie-chart>

      <apex-bar-chart v-else-if="chartType == 'bar'" :data-url="chartDataUrl"></apex-bar-chart>

      <apex-radar-chart v-else-if="chartType == 'radar'" :data-url="chartDataUrl"></apex-radar-chart>

      <apex-area-chart v-else-if="chartType == 'area'" :data-url="chartDataUrl"></apex-area-chart>

      <apex-column-chart v-else-if="chartType == 'column'" :data-url="chartDataUrl"></apex-column-chart>-->
    </div>
  </div>
</template>

<script>
  import ApexHeatmapChart from '../apexCharts/ApexHeatmapChart'
  //import ApexBubbleChart from '../apexCharts/ApexBubbleChart'
  //import ApexLineChart from '../apexCharts/ApexLineChart'
  //import ApexPieChart from '../apexCharts/ApexPieChart'
  //import ApexBarChart from '../apexCharts/ApexBarChart'
  //import ApexRadarChart from '../apexCharts/ApexRadarChart'
  //import ApexAreaChart from '../apexCharts/ApexAreaChart'
  //import ApexColumnChart from '../apexCharts/ApexColumnChart'

  export default {
    name:'SmartFullChartsComponent',
    props: {
      getPayload: String,
      chartType: String
    },
    components: {
      ApexHeatmapChart
      //ApexBubbleChart,
      //ApexLineChart,
      //ApexPieChart,
      //ApexBarChart,
      //ApexRadarChart,
      //ApexAreaChart,
      //ApexColumnChart
    },
    computed: {
      chartDataUrl: function () {
        return this.getPayload;
      }
    }
  }
</script>
